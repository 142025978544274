import { ReactNode } from 'react';
import styled, { keyframes } from 'styled-components';

type Props = {
  children: ReactNode;
};

const vibratingAnimation = keyframes`
  0% {
    filter: url('#vibrating-0');
  }
  25% {
    filter: url('#vibrating-1');
  }
  50% {
    filter: url('#vibrating-2');
  }
  75% {
    filter: url('#vibrating-3');
  }
  100% {
    filter: url('#vibrating-4');
  }      
`;

const VibratingFilter = styled.svg`
  display: none;
`;

const VibratingContainer = styled.div`
  color: black;
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  animation: ${vibratingAnimation} 0.34s linear infinite;
  display: inline-block;
  vertical-align: middle;
  width: 100%;

  outline: none;
  text-align: center;
  line-height: 1;
  font-size: 48px;
  font-weight: 600;
  font-family: 'Concert One', cursive;
`;

const VibratingText = ({ children }: Props) => {
  return (
    <>
      <VibratingContainer>{children}</VibratingContainer>

      <VibratingFilter version="1.1">
        <defs>
          <filter id="vibrating-0">
            <feTurbulence
              id="turbulence"
              baseFrequency="5"
              numOctaves="1"
              result="noise"
              seed="0"
            />
            <feDisplacementMap
              id="displacement"
              in="SourceGraphic"
              in2="noise"
              scale="2"
            />
          </filter>
          <filter id="vibrating-1">
            <feTurbulence
              id="turbulence"
              baseFrequency="0.02"
              numOctaves="3"
              result="noise"
              seed="1"
            />
            <feDisplacementMap in="SourceGraphic" in2="noise" scale="3" />
          </filter>

          <filter id="vibrating-2">
            <feTurbulence
              id="turbulence"
              baseFrequency="5"
              numOctaves="1"
              result="noise"
              seed="2"
            />
            <feDisplacementMap in="SourceGraphic" in2="noise" scale="2" />
          </filter>
          <filter id="vibrating-3">
            <feTurbulence
              id="turbulence"
              baseFrequency="0.02"
              numOctaves="3"
              result="noise"
              seed="3"
            />
            <feDisplacementMap in="SourceGraphic" in2="noise" scale="3" />
          </filter>

          <filter id="vibrating-4">
            <feTurbulence
              id="turbulence"
              baseFrequency="5"
              numOctaves="1"
              result="noise"
              seed="4"
            />
            <feDisplacementMap in="SourceGraphic" in2="noise" scale="2" />
          </filter>
        </defs>
      </VibratingFilter>
    </>
  );
};

export default VibratingText;
