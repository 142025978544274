import styled, { keyframes } from 'styled-components';

const animationFrames = keyframes`
  0% {
    opacity: 0;
    transform: scale(1.3)
  }
  50% {
    opacity: 0.5;
    transform: scale(1.0)
  }
  100% {
    opacity: 1;
  }
`;

const Heart = styled.path`
  fill: #000000;
`;

const Letter = styled.path`
  opacity: 0;
  fill: white;
  animation: ${animationFrames} 3s ease forwards;
`;

const F = styled(Letter)`
  animation-delay: 0.1s;
`;

const R = styled(Letter)`
  animation-delay: 0.2s;
`;

const FirstI = styled(Letter)`
  animation-delay: 0.3s;
`;

const D = styled(Letter)`
  animation-delay: 0.4s;
`;

const A = styled(Letter)`
  animation-delay: 0.5s;
`;

const Y = styled(Letter)`
  animation-delay: 0.6s;
`;

const N = styled(Letter)`
  animation-delay: 0.7s;
`;

const SecondI = styled(Letter)`
  animation-delay: 0.8s;
`;

const G = styled(Letter)`
  animation-delay: 0.9s;
`;

const H = styled(Letter)`
  animation-delay: 1s;
`;

const T = styled(Letter)`
  animation-delay: 1.1s;
`;

const C = styled(Letter)`
  animation: ${animationFrames} 0.5s normal forwards;
  animation-delay: 3s;
`;

const SecondR = styled(Letter)`
  animation: ${animationFrames} 0.5s normal forwards;
  animation-delay: 3s;
`;

const E = styled(Letter)`
  animation: ${animationFrames} 0.5s normal forwards;
  animation-delay: 3s;
`;

const W = styled(Letter)`
  animation: ${animationFrames} 0.5s normal forwards;
  animation-delay: 3s;
`;

const AnimatedSvg = styled.svg`
  height: 250px;
  width: 250px;
`;

const AnimatedLogo = () => {
  return (
    <AnimatedSvg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="1584.000000pt"
      height="1342.000000pt"
      viewBox="0 0 1584.000000 1342.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,1342.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <Heart
          d="M4315 13344 c-516 -33 -641 -47 -955 -110 -656 -130 -1243 -375
-1662 -691 -553 -418 -1107 -1226 -1359 -1983 -103 -309 -184 -695 -225 -1075
-24 -218 -24 -704 -1 -935 59 -572 203 -1053 487 -1620 303 -606 653 -1117
1274 -1859 94 -112 185 -221 201 -241 174 -212 668 -753 939 -1028 752 -764
1841 -1676 2641 -2212 245 -164 427 -291 700 -485 347 -247 499 -352 780 -537
296 -195 421 -272 570 -347 188 -96 235 -115 259 -102 11 5 74 35 139 65 259
121 730 426 1472 956 160 113 415 290 567 393 376 252 671 468 992 726 93 75
183 147 200 161 200 160 680 574 966 834 448 407 1102 1091 1515 1587 17 19
107 127 201 240 612 732 958 1237 1259 1839 364 726 515 1341 515 2101 0 628
-135 1311 -368 1854 -112 262 -320 636 -462 830 -200 274 -319 416 -490 585
-289 287 -611 495 -1042 674 -327 136 -592 211 -1053 300 -285 55 -843 93
-1135 76 -390 -22 -540 -42 -805 -110 -252 -64 -439 -133 -675 -252 -435 -217
-798 -501 -1159 -903 -226 -252 -355 -438 -545 -787 -51 -94 -82 -128 -116
-128 -42 0 -61 23 -142 175 -195 366 -416 658 -731 961 -263 254 -499 436
-762 587 -137 78 -404 203 -540 252 -157 57 -470 138 -617 159 -219 33 -673
60 -833 50z"
        />
        <F
          d="M2619 10881 c-41 -15 -159 -67 -264 -116 -176 -84 -193 -94 -250
-154 -33 -35 -103 -107 -155 -160 -63 -63 -102 -111 -114 -141 -11 -25 -40
-92 -67 -150 -26 -58 -54 -121 -62 -141 -8 -20 -37 -61 -65 -92 -78 -86 -98
-159 -117 -422 -4 -49 -13 -117 -22 -150 -8 -33 -19 -127 -23 -210 -5 -82 -12
-199 -15 -260 -7 -103 -5 -118 24 -227 17 -65 38 -132 47 -150 9 -17 30 -81
46 -141 16 -60 44 -140 63 -177 20 -40 39 -96 45 -137 7 -43 25 -96 49 -143
21 -41 48 -109 61 -150 35 -119 98 -271 135 -326 19 -29 43 -80 55 -115 11
-35 36 -90 55 -122 19 -32 42 -84 51 -115 9 -31 23 -70 31 -85 8 -16 16 -49
18 -74 5 -62 22 -98 51 -112 38 -17 48 -15 161 39 58 28 117 53 132 56 14 4
73 27 130 51 105 44 170 83 206 123 29 32 25 101 -9 168 -15 30 -33 88 -41
130 -19 104 -61 282 -84 351 -11 31 -29 101 -41 156 -11 55 -32 136 -46 181
-13 44 -24 89 -24 100 0 30 77 72 405 222 72 33 148 65 170 72 66 21 94 39
111 72 15 29 15 38 -6 137 -13 58 -29 123 -36 144 -8 21 -18 76 -24 122 -15
119 -54 289 -73 319 -26 39 -74 48 -161 27 -41 -10 -91 -28 -112 -41 -22 -12
-70 -33 -108 -46 -38 -13 -103 -44 -145 -68 -68 -41 -218 -114 -280 -137 l-24
-10 6 128 c10 227 28 396 47 443 33 81 95 225 108 247 7 12 38 36 70 53 31 17
92 56 135 88 120 89 273 171 345 183 89 16 96 27 100 165 l4 114 -44 78 c-25
42 -64 124 -87 182 -53 131 -98 196 -154 220 -71 30 -124 30 -208 1z"
        />
        <R
          d="M4500 11724 c-19 -3 -80 -13 -135 -24 -55 -11 -131 -22 -170 -26
-108 -9 -175 -29 -360 -106 -93 -39 -204 -82 -245 -95 -129 -43 -168 -68 -297
-197 l-122 -121 5 -45 c3 -25 26 -130 51 -235 37 -154 47 -217 54 -330 4 -83
18 -189 33 -260 14 -66 33 -199 42 -295 14 -154 46 -359 60 -382 3 -5 16 -70
29 -146 53 -305 66 -370 90 -445 14 -43 25 -86 25 -96 0 -10 11 -63 25 -117
14 -55 30 -130 35 -169 12 -81 27 -108 74 -130 32 -15 41 -15 118 0 46 10 126
37 179 61 53 24 101 44 108 44 21 0 92 41 98 56 3 8 1 25 -5 37 -6 12 -15 90
-21 172 -7 92 -20 188 -36 250 -30 121 -48 238 -43 283 3 32 7 26 70 -85 36
-65 81 -153 98 -195 18 -42 55 -105 83 -140 28 -35 75 -107 104 -161 29 -54
72 -124 96 -155 24 -31 63 -91 87 -132 24 -41 62 -102 86 -135 23 -33 63 -100
89 -150 50 -94 105 -169 162 -220 19 -16 53 -61 76 -100 26 -43 58 -81 85
-100 40 -27 51 -30 115 -29 77 2 251 43 302 72 17 10 44 39 60 65 17 26 49 77
73 113 48 73 50 91 22 168 -20 53 -31 70 -121 183 -71 89 -225 343 -289 478
-32 66 -77 142 -100 170 -23 27 -60 84 -81 127 -21 42 -66 110 -100 150 -34
40 -87 111 -117 158 -30 47 -83 115 -118 153 -38 39 -79 97 -100 140 -20 39
-58 99 -85 133 -42 53 -129 192 -129 206 0 3 19 10 43 17 70 21 211 99 304
168 49 35 138 90 198 121 101 51 121 67 243 186 180 176 205 231 183 401 -10
82 -77 268 -126 350 -34 57 -102 121 -243 224 -159 117 -204 132 -392 136 -74
2 -151 1 -170 -1z m-110 -717 c52 -18 67 -27 74 -49 12 -34 -1 -50 -68 -83
-46 -23 -284 -95 -312 -95 -12 0 -26 48 -39 125 -6 37 -4 41 26 59 48 29 168
65 214 65 22 0 69 -10 105 -22z"
        />
        <FirstI
          d="M5841 11433 c-71 -2 -130 -8 -138 -15 -7 -6 -13 -26 -13 -44 0 -56
-69 -361 -102 -448 -83 -223 -162 -492 -189 -652 -16 -92 -19 -151 -17 -259 5
-166 25 -238 121 -425 32 -63 68 -144 79 -180 11 -36 29 -82 40 -103 10 -21
25 -84 33 -140 7 -56 25 -147 38 -202 14 -55 31 -138 37 -184 6 -46 22 -108
36 -139 30 -69 80 -240 89 -307 18 -135 57 -198 144 -239 42 -19 63 -21 256
-21 116 1 231 4 258 9 52 8 99 44 116 88 17 46 4 555 -20 763 -31 271 -37 305
-69 360 -67 116 -242 479 -265 550 -48 149 -42 489 12 639 14 39 75 259 94
338 5 20 16 83 24 140 8 57 22 126 31 154 23 73 7 106 -91 180 -104 80 -122
91 -203 119 -48 17 -89 24 -124 23 -29 -1 -109 -4 -177 -5z"
        />
        <D
          d="M7095 10759 c-34 -10 -85 -53 -85 -73 0 -7 -11 -33 -24 -57 -31 -56
-52 -146 -71 -311 -9 -72 -18 -135 -21 -140 -6 -9 -28 -297 -28 -368 0 -25 3
-191 6 -370 5 -289 8 -332 26 -390 12 -36 28 -94 37 -129 9 -34 38 -102 65
-150 28 -48 55 -111 61 -141 14 -72 14 -250 -2 -345 -13 -82 -8 -185 11 -226
22 -48 48 -54 229 -53 91 1 213 3 271 4 58 2 152 -2 209 -9 57 -8 131 -11 163
-7 64 6 207 63 276 109 24 16 88 44 144 63 82 27 110 42 153 81 29 27 71 63
94 81 23 19 53 57 67 85 15 28 50 86 79 128 29 43 62 107 75 145 12 38 37 103
55 144 36 82 47 144 67 374 11 142 11 154 -10 250 -53 244 -189 692 -240 793
-22 41 -59 79 -107 109 -16 10 -52 36 -78 56 -66 51 -139 72 -239 66 -69 -4
-89 -10 -161 -48 -45 -23 -114 -64 -153 -91 -40 -26 -92 -53 -117 -60 -48 -12
-176 -8 -257 8 -97 19 -170 109 -190 233 -18 115 -28 138 -73 172 -93 68 -162
88 -232 67z m686 -1059 c20 -11 124 -164 140 -207 7 -18 3 -37 -12 -73 -20
-47 -20 -54 -9 -250 10 -181 10 -204 -5 -223 -57 -78 -67 -95 -72 -119 -3 -15
-13 -34 -23 -43 -17 -15 -20 -14 -44 18 -63 83 -96 310 -67 466 9 45 10 111 4
225 -4 89 -8 168 -8 176 0 11 41 34 73 39 2 1 12 -4 23 -9z"
        />
        <A
          d="M9730 11495 c-7 -8 -18 -15 -24 -15 -29 0 -119 -54 -201 -120 -184
-147 -285 -262 -325 -368 -34 -90 -25 -236 31 -467 42 -177 43 -183 42 -350 0
-93 -1 -271 -2 -395 -1 -193 -4 -238 -21 -305 -11 -44 -25 -124 -31 -177 -5
-54 -16 -116 -24 -138 -8 -23 -17 -71 -21 -108 -11 -126 -75 -374 -110 -430
-8 -12 -31 -69 -50 -124 -20 -56 -45 -120 -56 -142 -43 -84 -69 -171 -69 -225
0 -53 2 -58 45 -98 55 -52 63 -53 271 -17 83 14 193 29 245 34 186 17 273 31
300 47 36 21 78 87 94 148 61 233 85 321 116 435 41 149 60 267 60 379 1 131
3 133 132 141 64 4 115 13 134 23 18 9 50 19 71 23 l40 6 12 -58 c6 -33 24
-126 41 -209 16 -82 42 -204 58 -270 16 -66 35 -160 42 -210 25 -183 28 -197
61 -231 24 -25 41 -32 85 -37 59 -6 207 13 269 36 22 8 58 17 80 21 22 4 63
18 90 31 28 13 63 26 78 30 45 9 52 39 34 157 -9 57 -25 168 -37 248 -73 510
-80 541 -185 810 -65 166 -129 304 -182 390 -22 36 -46 83 -53 105 -7 22 -29
81 -50 130 -20 50 -49 123 -64 163 -16 40 -46 100 -68 133 -22 33 -55 98 -74
145 -18 47 -49 106 -67 132 -19 26 -47 82 -63 126 -15 43 -37 93 -50 111 -12
18 -43 74 -69 124 -33 63 -60 102 -88 124 -22 18 -74 64 -117 103 -78 73 -117
98 -211 134 -64 24 -102 26 -119 5z m242 -1423 c21 -15 45 -43 54 -62 9 -19
22 -48 30 -64 8 -16 14 -32 14 -36 0 -9 -159 -55 -166 -48 -2 3 -7 54 -10 114
-2 60 -7 122 -10 138 l-6 29 28 -21 c16 -13 46 -35 66 -50z"
        />
        <Y
          d="M10807 11614 c-36 -11 -52 -12 -62 -4 -18 15 -41 -10 -55 -58 -11
-36 -30 -257 -24 -277 1 -5 10 -86 19 -180 24 -250 42 -373 74 -503 16 -65 36
-162 45 -217 25 -154 96 -354 168 -476 64 -109 97 -157 134 -199 13 -14 39
-59 59 -99 36 -73 109 -164 240 -297 47 -49 71 -65 113 -78 79 -23 165 -80
224 -147 41 -47 71 -69 142 -105 88 -45 93 -46 161 -41 40 3 91 15 120 28 30
13 86 26 140 31 108 11 138 26 188 99 56 81 106 128 149 140 45 12 70 46 93
126 8 28 31 85 50 125 19 40 35 80 35 89 0 10 16 68 35 129 19 61 35 127 35
145 0 19 4 36 8 39 11 6 84 -113 115 -189 26 -63 69 -264 112 -525 14 -85 32
-177 40 -203 18 -59 39 -256 32 -294 -7 -39 -61 -193 -82 -234 -33 -66 -97
-141 -159 -187 -35 -26 -91 -71 -125 -100 -85 -72 -223 -140 -298 -148 -32 -3
-88 -12 -125 -20 -38 -8 -123 -21 -190 -30 -68 -8 -151 -22 -186 -30 -35 -8
-101 -14 -148 -14 -164 0 -214 -54 -185 -199 6 -33 16 -95 21 -138 17 -142 30
-218 41 -245 14 -34 55 -49 118 -41 33 4 72 0 114 -12 53 -14 89 -16 189 -12
211 10 266 20 359 70 46 25 139 69 207 98 92 40 145 71 208 121 47 37 117 84
155 104 93 49 212 153 256 222 94 151 202 303 258 362 89 93 112 146 174 397
41 165 56 339 56 638 -1 506 -34 675 -176 885 -25 36 -55 93 -69 128 -14 35
-46 91 -73 125 -26 34 -80 105 -120 157 -119 157 -180 223 -289 312 -57 47
-132 118 -167 158 -122 137 -299 280 -348 280 -13 0 -23 5 -23 10 0 15 -42 12
-64 -5 -11 -8 -44 -22 -75 -31 -80 -22 -261 -115 -278 -141 -14 -20 -14 -26 0
-46 10 -15 12 -29 6 -38 -14 -23 -10 -97 7 -132 9 -18 33 -50 54 -72 37 -38
39 -46 60 -170 12 -71 30 -161 40 -200 65 -244 73 -284 68 -347 -5 -62 -61
-208 -79 -208 -5 0 -33 -29 -62 -65 -46 -55 -146 -135 -197 -156 -8 -4 -26 -2
-40 4 -14 6 -56 13 -95 16 l-70 6 -24 43 c-13 24 -48 68 -77 99 -49 50 -57 64
-89 166 -19 62 -44 158 -54 214 -11 56 -28 117 -38 135 -15 29 -18 58 -17 223
0 173 15 478 30 615 3 31 1 74 -5 98 -14 50 -67 107 -108 115 -40 7 -87 25
-168 64 -43 21 -104 40 -157 49 -49 8 -113 26 -143 40 -64 28 -134 31 -208 8z
m580 -190 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"
        />
        <N
          d="M4210 8425 c-25 -7 -66 -18 -93 -24 -88 -20 -132 -49 -246 -165 -62
-62 -121 -126 -132 -142 -22 -34 -25 -82 -5 -90 7 -3 14 -16 14 -28 0 -37 96
-239 177 -371 40 -66 123 -218 185 -339 61 -120 131 -253 155 -295 24 -42 57
-110 73 -151 16 -41 50 -108 75 -147 26 -40 67 -114 92 -164 26 -50 64 -118
87 -150 22 -33 39 -62 37 -64 -9 -9 -304 100 -397 146 -102 52 -104 52 -222
59 l-119 7 -138 66 c-76 36 -172 75 -213 87 -41 12 -118 42 -170 65 -52 24
-149 65 -214 90 -68 26 -134 59 -154 76 -35 31 -48 32 -102 4 -28 -15 -98 -86
-121 -124 -8 -13 -30 -29 -49 -35 -22 -7 -46 -28 -67 -56 -46 -63 -98 -130
-168 -216 -64 -79 -105 -139 -105 -155 0 -18 101 -161 201 -286 54 -68 126
-164 160 -215 34 -51 79 -113 99 -138 21 -25 86 -117 146 -205 192 -285 381
-511 709 -845 127 -129 246 -255 265 -280 52 -69 209 -230 270 -277 51 -40 59
-43 117 -43 77 0 230 52 312 106 74 50 148 120 183 173 27 44 37 94 19 105 -4
3 -11 23 -15 44 -6 43 -116 206 -219 326 -374 439 -498 579 -604 687 -114 115
-124 128 -114 148 15 28 41 27 104 -6 29 -14 84 -34 123 -44 45 -11 97 -34
140 -62 38 -24 106 -59 151 -77 46 -18 118 -53 160 -77 43 -25 102 -58 131
-75 42 -24 72 -33 139 -39 62 -7 106 -18 162 -43 91 -41 132 -52 231 -61 75
-7 234 -36 300 -55 52 -15 117 -12 226 10 116 24 187 54 232 99 48 49 116 140
142 191 12 25 44 71 71 102 52 59 61 96 38 141 -20 38 -260 399 -304 457 -67
88 -213 328 -235 387 -44 117 -202 394 -297 519 -88 117 -153 215 -238 365
-42 74 -103 170 -134 214 -74 103 -189 270 -268 388 -107 161 -136 197 -243
311 -58 61 -110 120 -115 130 -14 25 -91 55 -140 54 -22 0 -60 -6 -85 -13z"
        />
        <SecondI
          d="M6167 7745 c-37 -8 -93 -23 -123 -34 -30 -12 -72 -21 -93 -21 -63 0
-134 -27 -208 -80 -70 -48 -92 -78 -77 -102 4 -6 9 -50 11 -97 2 -47 11 -109
19 -138 8 -28 14 -60 14 -70 0 -10 16 -52 36 -93 35 -74 58 -130 115 -282 39
-106 62 -148 99 -183 17 -16 49 -59 70 -95 46 -77 72 -107 123 -144 28 -21 49
-51 79 -113 29 -60 57 -101 98 -141 73 -73 117 -130 168 -217 33 -56 45 -91
61 -175 34 -180 46 -309 46 -482 l0 -168 28 -26 c28 -26 29 -26 105 -15 42 6
120 15 172 20 52 5 128 15 168 20 39 6 104 15 144 20 92 13 148 48 165 106 6
22 28 92 49 155 l38 115 0 195 c1 192 1 196 -26 256 -15 33 -32 94 -38 135
-14 94 -111 293 -183 375 -53 58 -118 170 -196 329 -40 85 -58 110 -114 160
-57 52 -75 78 -132 189 -97 187 -131 284 -149 418 -19 151 -25 165 -73 172
-44 7 -288 26 -312 25 -9 0 -47 -7 -84 -14z"
        />
        <G
          d="M8195 7972 c-117 -37 -209 -84 -320 -166 -158 -115 -186 -139 -254
-215 -36 -41 -89 -100 -118 -132 -56 -62 -109 -158 -144 -260 -19 -58 -21 -77
-15 -194 7 -146 35 -320 72 -440 13 -44 24 -91 24 -105 0 -14 30 -88 66 -165
37 -77 101 -225 144 -330 149 -363 146 -357 245 -473 85 -100 98 -111 196
-164 58 -32 109 -58 113 -58 4 0 39 -14 77 -30 l69 -31 121 10 c138 11 186 29
259 96 26 24 64 47 90 54 64 19 128 53 177 96 44 38 137 184 216 340 68 133
71 136 132 124 63 -13 315 -12 349 0 48 19 51 38 48 276 -2 236 -11 311 -40
353 -10 15 -24 46 -31 70 -21 73 -26 74 -329 79 -277 4 -300 7 -447 59 -23 8
-56 14 -75 14 -19 0 -60 7 -90 15 -102 27 -322 15 -393 -21 -44 -24 -52 -53
-38 -142 14 -89 16 -137 11 -323 -3 -134 4 -171 34 -191 18 -12 99 -9 152 6
102 29 147 27 109 -4 -8 -6 -26 -33 -41 -58 -38 -66 -62 -90 -118 -119 -82
-42 -149 -16 -226 87 -31 41 -44 73 -63 154 -14 55 -38 139 -55 186 -44 126
-121 378 -134 443 -9 44 -9 70 0 119 16 79 70 190 120 245 l39 42 139 -4 c191
-5 327 -19 402 -43 60 -19 94 -19 119 0 26 21 107 164 129 230 13 38 33 82 44
97 27 39 39 102 29 155 -12 65 -26 82 -89 105 -30 11 -85 38 -122 60 -36 22
-85 43 -107 47 -31 5 -53 18 -90 55 -27 27 -60 53 -73 58 -12 5 -68 11 -123
15 -91 5 -108 3 -190 -22z"
        />
        <H
          d="M11185 8226 c-73 -14 -183 -48 -223 -68 -13 -7 -32 -24 -43 -39 -19
-27 -19 -28 2 -105 55 -208 89 -362 89 -410 1 -22 7 -68 15 -104 8 -36 19
-162 25 -280 6 -118 15 -282 21 -363 6 -82 8 -151 6 -154 -3 -2 -86 -5 -185
-6 l-179 -2 -7 175 c-4 107 -3 196 4 229 5 29 10 108 9 175 0 66 5 180 11 252
13 143 2 305 -24 368 -23 54 -72 102 -132 126 -51 21 -66 22 -182 17 -211 -9
-401 -51 -429 -94 -14 -20 -14 -27 0 -60 8 -21 18 -72 21 -113 4 -41 13 -127
21 -190 8 -63 20 -171 26 -240 6 -69 15 -163 20 -210 10 -101 -5 -331 -27
-405 -9 -27 -22 -104 -29 -170 -20 -172 -37 -266 -88 -470 -24 -99 -47 -218
-51 -264 -4 -53 -15 -102 -28 -130 -20 -45 -22 -60 -30 -310 -1 -27 6 -41 35
-68 20 -18 45 -33 55 -33 10 0 51 -11 90 -25 53 -19 92 -25 151 -25 109 0 128
17 172 148 17 53 40 111 50 128 10 17 25 60 32 95 8 35 31 93 52 129 21 36 46
81 55 100 28 55 57 180 58 243 0 33 2 61 5 63 7 8 212 -17 264 -31 65 -19 129
-19 181 -1 23 8 43 12 46 8 2 -4 12 -44 22 -89 15 -71 16 -94 6 -165 -6 -46
-14 -126 -16 -179 -6 -108 -36 -216 -91 -327 -19 -39 -42 -101 -51 -138 -10
-38 -31 -89 -47 -113 -28 -41 -29 -45 -16 -83 7 -21 22 -44 33 -50 27 -15 246
-13 326 3 35 7 95 9 142 6 45 -3 91 -2 103 3 24 9 45 56 45 100 0 15 11 47 23
71 17 32 26 69 30 132 5 58 15 108 32 146 25 60 70 239 87 346 5 33 11 139 12
235 2 96 8 229 14 295 50 521 44 784 -23 1030 -14 50 -48 205 -76 345 -28 140
-59 278 -69 305 -37 103 -87 216 -102 231 -21 22 -154 27 -243 10z"
        />
        <T
          d="M13625 7774 c-38 -16 -78 -44 -108 -75 -26 -27 -76 -63 -110 -80 -34
-17 -82 -48 -107 -69 -25 -21 -67 -47 -94 -59 -28 -12 -73 -39 -100 -60 -67
-50 -157 -100 -231 -129 -114 -45 -265 -92 -291 -92 -14 0 -67 -13 -117 -30
-51 -16 -153 -41 -227 -54 -207 -38 -333 -66 -347 -78 -7 -6 -13 -26 -13 -45
0 -19 -7 -63 -16 -96 -10 -39 -16 -104 -16 -177 0 -63 -4 -140 -10 -171 -13
-72 5 -113 57 -128 43 -12 136 7 218 43 32 14 63 26 70 26 7 0 60 20 119 44
59 24 126 47 149 51 l42 7 -7 -343 c-6 -301 -9 -362 -31 -488 -38 -220 -32
-291 30 -339 43 -34 86 -28 182 24 67 36 91 56 125 102 22 31 69 85 104 119
94 90 104 117 104 293 0 79 -5 192 -10 250 -10 99 -29 511 -24 518 1 2 33 14
72 27 38 13 96 37 128 53 33 16 84 37 114 47 30 10 100 39 155 64 86 41 109
57 162 115 85 93 128 196 154 371 38 253 37 318 -6 361 -30 30 -45 30 -120 -2z
m-1048 -1351 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z"
        />
        <C
          d="M6145 5016 c-80 -18 -120 -35 -229 -100 -50 -29 -122 -72 -161 -94
-80 -46 -214 -161 -296 -254 -32 -35 -83 -84 -115 -108 -158 -117 -259 -279
-320 -515 -32 -125 -36 -149 -31 -225 8 -115 41 -262 77 -335 43 -88 172 -249
243 -303 33 -26 92 -74 130 -107 38 -33 99 -84 135 -114 37 -29 105 -90 152
-135 47 -45 112 -100 146 -123 33 -23 94 -78 135 -124 41 -45 99 -103 130
-128 109 -89 215 -171 286 -223 163 -120 210 -156 290 -225 97 -85 110 -92
223 -127 66 -20 108 -42 185 -96 132 -92 162 -108 239 -124 63 -13 99 -30 205
-97 53 -33 56 -34 165 -34 100 0 116 3 161 26 172 89 254 141 320 200 40 37
101 82 136 100 35 19 94 65 134 104 68 68 73 76 152 256 45 102 87 207 94 233
25 99 -3 269 -85 517 -43 133 -61 169 -149 294 -39 55 -83 118 -98 140 -38 57
-79 96 -114 111 -36 15 -107 5 -134 -20 -9 -9 -22 -16 -28 -16 -18 0 -133 -79
-182 -125 -43 -40 -139 -95 -168 -95 -18 0 -72 -50 -89 -82 -21 -41 -11 -87
37 -169 45 -75 88 -231 116 -416 16 -112 16 -114 -4 -141 -11 -15 -39 -40 -62
-57 -40 -28 -49 -30 -134 -30 -100 0 -157 16 -236 67 -25 15 -53 28 -62 28 -9
0 -57 20 -107 45 -67 34 -109 64 -170 122 -44 43 -111 107 -148 143 -38 36
-92 90 -121 121 -36 39 -75 67 -135 96 -76 38 -100 59 -290 246 -154 153 -223
214 -271 241 -79 44 -117 85 -184 196 -60 99 -92 208 -100 343 l-6 88 74 70
c41 38 106 96 145 128 l71 58 131 7 130 7 99 -42 c141 -60 147 -59 197 26 23
39 59 122 80 185 46 137 61 168 115 239 26 34 41 65 41 83 0 53 -136 176 -270
245 -54 27 -60 28 -235 30 -123 2 -199 -2 -240 -11z"
        />
        <SecondR
          d="M7597 5140 c-114 -38 -225 -79 -247 -90 -53 -27 -289 -262 -334 -332
-19 -31 -46 -85 -60 -121 -36 -97 -55 -131 -166 -297 -29 -44 -50 -88 -50
-108 0 -12 34 -46 93 -93 50 -40 129 -110 174 -156 76 -77 88 -95 173 -265 50
-101 108 -206 128 -235 104 -148 156 -214 174 -224 57 -31 181 27 316 145 69
61 176 127 234 146 50 15 81 56 75 95 -3 17 -43 86 -90 155 -47 69 -84 126
-82 128 1 2 25 -8 52 -21 26 -13 69 -32 95 -42 28 -10 79 -45 120 -82 97 -86
177 -126 238 -118 83 12 276 111 317 164 21 26 9 130 -20 187 -46 89 -111 139
-222 169 -33 9 -117 44 -186 77 l-127 61 24 35 c13 19 24 39 24 44 0 5 25 63
55 128 67 146 97 260 84 321 -17 74 -57 144 -121 211 -50 52 -84 76 -163 115
-189 95 -234 95 -508 3z m-17 -635 c0 -12 -49 -113 -57 -118 -5 -3 -22 10 -37
30 l-29 36 24 18 c29 23 99 47 99 34z"
        />
        <E
          d="M9310 5435 c-90 -19 -287 -91 -335 -122 -91 -60 -229 -189 -270 -254
-49 -75 -51 -80 -76 -202 -26 -129 -26 -312 2 -387 11 -30 26 -79 33 -108 8
-32 26 -66 44 -85 16 -18 45 -57 65 -87 19 -30 73 -94 119 -141 79 -82 87 -86
163 -110 60 -18 103 -24 175 -24 91 0 99 2 180 41 101 49 191 138 241 237 18
34 47 82 66 106 33 44 55 106 87 251 45 199 65 314 71 413 8 132 0 147 -75
147 -58 0 -121 -38 -207 -126 -49 -50 -63 -71 -63 -94 0 -16 -9 -44 -20 -62
-15 -25 -20 -51 -20 -106 0 -59 -5 -82 -25 -120 -14 -26 -25 -53 -25 -60 0
-57 -153 -232 -203 -232 -49 0 -57 8 -42 43 7 18 21 61 30 97 17 67 50 124
117 197 21 24 38 53 38 65 0 12 6 40 14 63 8 22 17 52 20 67 3 14 24 45 46 68
22 23 40 48 40 56 0 8 9 32 20 54 11 22 20 51 20 64 0 24 28 93 71 178 10 20
19 48 19 61 0 28 -53 85 -96 103 -48 20 -152 24 -224 9z m-169 -398 c-5 -13
-54 -111 -108 -218 l-98 -194 -3 70 c-6 140 64 289 160 343 52 28 60 28 49 -1z"
        />
        <W
          d="M10368 4994 c-140 -64 -178 -86 -231 -135 -36 -32 -72 -75 -82 -96
-10 -20 -32 -57 -50 -82 -18 -25 -50 -80 -70 -121 -21 -41 -50 -94 -66 -116
-16 -23 -66 -104 -112 -180 -46 -77 -104 -173 -129 -214 -117 -195 -286 -512
-342 -643 -15 -37 -32 -67 -37 -67 -5 0 -21 23 -35 52 -42 82 -123 210 -158
246 -17 19 -44 40 -60 49 -27 14 -32 13 -80 -13 -28 -15 -108 -67 -178 -117
-150 -104 -143 -73 -58 -287 79 -198 104 -282 155 -513 24 -108 57 -234 74
-280 58 -163 83 -222 98 -239 21 -23 64 -23 94 0 13 10 87 54 164 97 226 127
239 137 263 194 12 27 22 54 22 59 0 36 92 273 124 323 13 20 43 73 66 119 23
46 66 118 94 159 72 104 192 310 236 404 l36 79 57 -119 c31 -66 65 -144 76
-174 53 -152 93 -209 147 -209 37 0 58 18 193 169 41 45 105 107 144 138 38
31 114 98 168 149 106 100 154 138 300 235 51 34 132 99 179 144 47 45 108 97
135 115 98 66 164 122 210 181 25 32 90 97 143 144 53 47 117 114 142 150 25
36 69 98 99 137 30 40 59 84 65 98 6 14 19 37 30 52 21 32 24 92 5 114 -24 29
-71 46 -110 41 -20 -3 -128 -34 -240 -70 -112 -36 -258 -82 -324 -102 -166
-51 -230 -83 -300 -148 -33 -31 -63 -57 -66 -57 -6 0 -174 -181 -249 -268 -24
-29 -47 -52 -51 -52 -3 0 -9 26 -12 58 -3 31 -22 109 -41 172 -19 63 -49 171
-65 239 -35 141 -70 224 -100 240 -11 6 -39 14 -63 17 -38 4 -62 -4 -210 -72z"
        />
      </g>
    </AnimatedSvg>
  );
};

export default AnimatedLogo;
