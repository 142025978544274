import { ReactNode } from 'react';
import styled from 'styled-components';

import { Path, useRouter } from '../lib/router';

const AnchorStyled = styled.a`
  text-decoration: none;
`;

type Props = {
  to: Path;
  children: ReactNode;
  className?: string;
};

const Link = ({ to, children, className }: Props) => {
  const { push } = useRouter();

  return (
    <AnchorStyled
      className={className}
      href={to}
      onClick={(e) => {
        e.preventDefault();
        push(to);
      }}
    >
      {children}
    </AnchorStyled>
  );
};

export default Link;
