import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

import { createBrowserHistory, Update } from 'history';

const history = createBrowserHistory();

// ADD NEW ROUTES HERE
export const PATHS = ['/', '/about', '/collection'] as const;

export type Path = typeof PATHS[number];

type RouterContextValue = {
  pathname: Path;
  push: (path: Path) => void;
};

const RouterContext = createContext<RouterContextValue>({
  pathname: '/',
  push: () => console.error('Please wrap component with RouterProvider'),
});

const getValidPath = (path: string) =>
  PATHS.find((validPath) => validPath === path) || PATHS[0];

const RouterProvider = ({ children }: { children: ReactNode }) => {
  const [route, setRoute] = useState<Path>(
    getValidPath(history.location.pathname),
  );

  const handleRouteUpdate = useCallback(
    ({ location: { pathname } }: Update) => {
      setRoute(getValidPath(pathname));
    },
    [],
  );

  useLayoutEffect(() => {
    let unlisten = history.listen(handleRouteUpdate);
    return () => {
      unlisten();
    };
  }, [handleRouteUpdate]);

  const value = useMemo<RouterContextValue>(
    () => ({
      pathname: route,
      push: history.push,
    }),
    [route],
  );

  return (
    <RouterContext.Provider value={value}>{children}</RouterContext.Provider>
  );
};

export const Router = ({ children }: { children: ReactNode }) => (
  <RouterProvider>{children}</RouterProvider>
);

export const useRouter = (): RouterContextValue => {
  return useContext(RouterContext);
};
