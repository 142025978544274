import React, { ReactNode, Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import styled, { keyframes } from 'styled-components/macro';

import './index.css';
import Link from './components/Link';
import VibratingText from './components/VibratingText';
import { Path, Router, useRouter } from './lib/router';
import BannerImage from './assets/banner.png';
import BannerMobileImage from './assets/mobile-banner.png';
import AnimatedLogo from './components/AnimatedLogo';

const About = lazy(() => import('./pages/About'));
const Collection = lazy(() => import('./pages/Collection'));
const Home = lazy(() => import('./pages/Home'));

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
`;

const Banner = styled.div`
  height: 100vh;
  width: 100vw;
  background-image: url(${BannerMobileImage});
  background-size: cover;
  background-position-x: right;
  filter: blur(2px);

  @media (min-width: 1000px) {
    width: 100%;
    background-image: url(${BannerImage});
    background-position: center;
    filter: none;
  }
`;

const HeartContainer = styled.div`
  position: absolute;
  top: calc(50% - 100px);
  text-align: center;

  @media (min-width: 1000px) {
    position: relative;
    top: 0;
    margin: 64px;
    left: 0;
    display: flex;
    align-items: center;
    flex: 1;
    flex-direction: column;
  }
`;

const fadeIn = keyframes`
  0% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const TextWrapper = styled.div`
  opacity: 0;
  animation: ${fadeIn} 1s normal forwards;
  animation-delay: 3s;
`;

const routes: Record<Path, () => ReactNode> = {
  '/': () => <Home />,
  '/about': () => <About />,
  '/collection': () => <Collection />,
};

const TemporaryTeaserPage = () => (
  <Container>
    <Banner />
    <HeartContainer>
      <AnimatedLogo />
      <TextWrapper>
        <VibratingText>COMING SOON</VibratingText>
      </TextWrapper>
    </HeartContainer>
  </Container>
);

// eslint-disable-next-line
const App = () => {
  const { pathname } = useRouter();

  return (
    <Container>
      <nav>
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/collection">Collection</Link>
      </nav>
      <Suspense fallback={<div />}>{routes[pathname]()}</Suspense>
    </Container>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <TemporaryTeaserPage />
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);
